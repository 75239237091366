<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Tambah Soal</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/event">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="addData()"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Nama</b></label>
            <b-form-input id="nama" v-model="nama" placeholder="Nama" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Deskripsi</b></label>
            <b-form-input
              id="deskripsi"
              v-model="deskripsi"
              placeholder="Deskripsi"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Catatan</b></label>
            <b-form-input
              id="catatan"
              v-model="catatan"
              placeholder="Catatan"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="SaveIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      event: [],
      id: this.$route.params.id,
      nama: "",
      deskripsi: "",
      catatan: "",
    };
  },
  methods: {
   async addData() {
    //   e.preventDefault();
      const formData = new FormData();
      formData.append("nama", this.nama);
      formData.append("deskripsi", this.deskripsi);
      formData.append("catatan", this.catatan);
    await  this.$http
      .post(process.env.VUE_APP_BACKEND_URL + "events/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Ubah Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "list_event" });
          this.nama = ""
          this.deskripsi = ""
          this.catatan = ""
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_event" });
    },
  },
};
</script>
